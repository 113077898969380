<!--  -->
<template>
  <div>

    <!-- <div class="top-place-holder"></div> -->
    <div class="box">
      <div class="box-content-view">
        <div class="box-content-left-view">
          <!-- 基于AI-Agent技术，轻松定制 -->
          <div class="box-content-title-lab">
            真人感AI客服，全渠道轻松获客
          </div>
          <!-- 个人AI助理/企业AI员工 -->
          <div class="box-content-title-lab2">AI获客 抖音私信 企微客服</div>
          <div class="box-content-detail-lab">
            <div>• 构建企业私有模型，帮你的企业快速进入AI时代</div>
            <div>• 定制AI机器人，与客户高情商对话，7*24小时高效获客</div>
          </div>


          <div class="modellist-title-btn">
            <!-- <div class="modellist-title-btn1" @click="createEmp(1)">
              免费创建助理/员工
            </div>
            <div class="modellist-title-btn2" @click="openConver">
              开启对话
            </div> -->
            <div class="modellist-title-btn1" @click="createEmp(1)">
              免费试用
            </div>
            <div class="modellist-title-btn2" @click="openConver">
              快速体验
            </div>
          </div>


        </div>
        <div class="box-content-right-view"></div>
      </div>
    </div>

    <el-dialog :visible.sync="codeDialogVisible" width="450px">
      <div class="codeBox">
        <img src="@/assets/appletImg.png" alt="">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import common from "common/js/common";
export default {
  data () {
    return {
      codeDialogVisible: false
    }
  },
  methods: {
    openConver () {
      // sessionStorage.setItem('main_index', 2)
      // this.$emit("updataIndexAction")
      // this.$router.replace({ path: '/model' })
      this.codeDialogVisible = true
    },
    createEmp (index) {
      // if (common.isStringEmpty(this.access_token)) {
      //   console.log("5656546");
      //   this.$emit("openLoginAction", index)
      // } else {
      //   location.href = this.$currentServe + "backend/#/emp/myyg";
      // }
      window.open(this.$currentServe + "crm")
    },

  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },

  computed: {

    access_token () {
      return this.$store.state.user.access_token
    },



  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.top-place-holder {
  width: 100vw;
  height: 56px;
}

.box {
  width: 100%;
  height: 600px;
  background-image: url("https://cdn.shhd.info/APP/sys_img/digitalEmployee/backGround1.png");
  background-repeat: no-repeat;
  background-position: center;

  .box-content-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: red; */
    width: 1210px;
    height: 600px;
    margin: 0 auto;

    .box-content-left-view {

      /* background: green; */
      font-family: PingFang SC-Semibold, PingFang SC;

      .box-content-title-lab {
        font-size: 40px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: #000000;

        letter-spacing: 2px;
      }

      .box-content-title-lab2 {
        font-size: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;

        letter-spacing: 2px;
        background: linear-gradient(299deg, #373FFF 0%, #0075FF 100%);
        margin-top: 36px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .box-content-detail-lab {
        width: 557px;
        margin-top: 52px;
        font-size: 18px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        // display: flex;
        // align-items: center;
        line-height: 30px;
        letter-spacing: 1px;

      }

      .modellist-title-btn {
        display: flex;
        margin-top: 30px;
      }

      .modellist-title-btn1 {
        width: 196px;
        height: 56px;

        background: linear-gradient(90deg, #277EFF 0%, #1444FA 100%);
        box-shadow: 0px 8px 28px 0px rgba(9, 69, 81, 0.12);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
        cursor: pointer;


      }

      .modellist-title-btn1:hover {
        transform: scaleX(1.05) scaleY(1.05) !important;
      }

      .modellist-title-btn2 {
        width: 196px;
        height: 56px;

        background: #FFFFFF;
        box-shadow: 0px 8px 28px 0px rgba(9, 69, 81, 0.12);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 1px solid #50B6FF;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #226cff;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
      }

      .modellist-title-btn2:hover {
        transform: scaleX(1.05) scaleY(1.05) !important;
      }

      .box-content-left-btn {
        margin-top: 45px;
        width: 140px;
        height: 43.5px;
        background: #226cff;
        box-shadow: 0px 10px 24px 0px rgba(11, 138, 255, 0.2);
        border-radius: 21.75px;
        opacity: 1;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 43.5px;
        padding-left: 25px;

        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 5px;

        .box-content-left-btn-point-img {
          width: 36px;
          height: 36px;
          border-radius: 18px;
          background-image: url("https://cdn.shhd.info/APP/sys_img/digitalEmployee/arrow.png");
          background-repeat: no-repeat;
          background-color: #3478ff;
          background-position: center;
          background-size: 18px 18px;
        }
      }
    }

    .box-content-right-view {
      margin-top: 110px;
      width: 540px;
      height: 495px;
      /* background: green; */
      background-image: url("https://cdn.shhd.info/APP/sys_img/digitalEmployee/robotImage.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%;
    }
  }
}

.codeBox {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 20px 0;

  img {
    width: 300px;
    height: 300px;
  }
}

::v-deep .el-dialog {
  border-radius: 10px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  font-size: 20px
}
</style>