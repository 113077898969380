<!--  -->
<template>
<div>
  <div class="backtotopwarpper">
      
    <div class="back-to-top" @click="goTop" v-show="isShowBackToTop"></div>
    </div>

</div>
</template>

<script>
export default {
data() {
    return {
      isShowBackToTop:false
}
  },
  methods: {
    goTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    },
    handleScroll () { 
      if (document.documentElement.scrollTop > window.innerHeight) {
        this.isShowBackToTop = true;
      } else { 
        this.isShowBackToTop = false;
      }
     
    }
  },
//生命周期 - 创建完成（访问当前this实例）
created() {

},
//生命周期 - 挂载完成（访问DOM元素）
mounted() {
  window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () { 
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */
.backtotopwarpper{
  position: fixed;
z-index:99 ;
bottom: 170px;
right: 30px;
  .back-to-top {
    width: 56px;
    height: 56px;
    background: #ffffff;
    box-shadow: 0px 6px 16px 0px #e3ecf5;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border-radius: 28px;
    background-image: url("https://cdn.shhd.info/APP/sys_img/digitalEmployee/shangArrow.png");
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
    background-size: 20px 20px;
    cursor: pointer;
  }
}
</style>