<!--  -->
<template>
  <div>

    <div class="home-center-content">

      <div class='home-title-main-lab'>产品功能</div>
      <div class='home-title-detail-lab'>创建您不同行业、领域的专家级AI客服，为企业减少成本、提效赋能</div>

      <div class="home-center-introduce">

        <div class=home-center-introducecell v-for="(item, index) in dataArr" :key="index">
          <div class="home-center-introduce-img">
            <img :src=item.imgUrl class="home-center-introduce-img-url" />
          </div>
          <div class="home-center-introduce-title-lab">
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/introductionIcon.png"
              class="home-center-introduce-title-img">
            {{ item.title }}
          </div>
          <div class="home-center-introduce-detail-lab">{{ item.detail }}</div>
        </div>

      </div>

      <div style="margin-top: 180px;">
        <div class="home-center-staff-info-title">
          <div>
            基于企业私有数据的AI数字员工
            <br />
            <span class="home-center-staff-info-small-title">
              创建您不同行业、领域的专家级数字员工，为企业减少成本、提效赋能
            </span>
          </div>
        </div>
        <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/view3.png" class="home-center-staff-info-img" />
      </div>

      <div class="home-center-core">
        <div class="home-center-core-technology-title">
          <div>
            AI数字员工核心技术
            <br />
            <span class="home-center-core-technology-small-title">
              创建您不同行业、领域的专家级数字员工，为企业减少成本、提效赋能
            </span>
          </div>
        </div>
        <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/view2.png"
          class="home-center-core-technology-img" />
      </div>

      <div class="home-center-price-warpper" id="home-center-price" v-if="false">
        <div class="home-center-price-top-box">
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/backGround2.png "
            class="home-center-price-top-bg-img" />
          <div class="home-center-price-top-title">
            选择方案
            <br />
            <span class="home-center-price-top-detail-title">
              让我们为您找到合适的计划。您可以从免费计划开始，并在需要更多功能时升级。
            </span>
          </div>
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/employees.png"
            class="home-center-price-top-so-img" />
        </div>

        <div class="home-center-price-collect-box">
          <div class="home-center-price-collect-content">

            <div class="home-center-pricecell-warpper">
              <div class="home-center-price-cell-title-one-box">免费试用</div>
              <div class="home-center-price-cell-content-box">

                <div class="home-center-price-cell-content-list" v-for="(item, index) in priceList[0]" :key="index">
                  <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/shareSuccess.png"
                    class="home-center-price-cell-content-list-img" />
                  {{ item }}
                </div>


              </div>

              <div></div>
              <div class="home-center-price-cell-btn">立即体验</div>
            </div>


            <div class="home-center-pricecell-warpper">


              <div class="home-center-price-cell-title-two-box">
                <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/base.png "
                  style="width: 28px;height: 28px;" />
                基础版
                <div class="home-center-price-cell-sub-title">
                  适合中小型业务使用
                </div>
              </div>

              <div class="home-center-price-cell-content-box">

                <div class="home-center-price-cell-content-list" v-for="(item, index) in priceList[1]" :key="index">
                  <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/shareSuccess.png"
                    class="home-center-price-cell-content-list-img" />
                  {{ item }}
                </div>


              </div>

              <div class="home-center-price-cell-price-lab">
                <span style="font-size: 16px;font-weight: 600;">￥</span>
                <span style="font-size: 36px;font-weight: 600;">599</span>
                <span style="font-size: 14px;font-weight: 400;">/月</span>
              </div>
              <div class="home-center-price-cell-btn">联系我们</div>
            </div>




            <div class="home-center-pricecell-warpper">

              <div class="home-center-price-cell-title-three-box">
                <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/senior.png "
                  style="width: 28px;height: 28px;" />
                专业版
                <div class="home-center-price-cell-sub-title">
                  满足更多样的企业定制需求
                </div>
              </div>

              <div class="home-center-price-cell-content-box">

                <div class="home-center-price-cell-content-list" v-for="(item, index) in priceList[2]" :key="index">
                  <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/shareSuccess.png"
                    class="home-center-price-cell-content-list-img" />
                  {{ item }}
                </div>


              </div>

              <div class="home-center-price-cell-price-lab">

                <span style="font-size: 32px;font-weight: 600;">根据企业需求定制</span>

              </div>
              <div class="home-center-price-cell-btn">联系我们</div>
            </div>


          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {

      dataArr: [
        {
          imgUrl: "https://cdn.shhd.info/APP/sys_img/digitalEmployee/introduce1.png",
          title: "AI智能体训练（可私有化）",
          detail: "· 基于大模型技术，支持私有化部署\n· 通过RAG和Agent架构，构建企业私有模型\n· 帮你的企业跑步进入AI时代",
        },
        {
          imgUrl: "https://cdn.shhd.info/APP/sys_img/digitalEmployee/introduce2.png",
          title: " 抖音私信通（AI版）",
          detail: "· 接管抖音私信，与客户高情商对话\n· 提升留咨转化20%，7*24小时高效获客\n· 适用汽车、房地产、本地生活等多个行业",
        },
        {
          imgUrl: "https://cdn.shhd.info/APP/sys_img/digitalEmployee/introduce3.png",
          title: " 微信私域运营（AI版）",
          detail: "· 对接企业微信客服/公众号/视频号/企微和个微\n· 为私域客户提供专业高效服务\n· 智能挖掘客户需求，增加复购",
        },
      ],
      priceList: [
        [
          "可配置2个员工",
          "最多上传10个文档",
          "文档字数不超过10万个字",
          "对话次数上限300次/月",
        ],
        [
          "可配置10个员工",
          "最多上传30个文档",
          "文档字数不超过100万个字",
          "对话次数上限4000次/月",
        ],
        [
          "可配置自定义数量的员工",
          "基于企业需求的内容上传及训练",
          "企业所需的定制功能",
          "企业数据集成",
        ],
      ]

    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  }
}
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */
.home-center-content {
  width: 1200px;
  margin: 0 auto;
}

.home-title-main-lab {
  width: 160px;
  height: 30px;
  font-size: 30px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 40px;
}

.home-title-detail-lab {
  margin-top: 16px;
  height: 18px;
  font-size: 13.5px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
}

.home-center-introduce {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  .home-center-introducecell {

    width: 380px;
    height: 306px;
    background: #ffffff;
    border-radius: 14px 14px 14px 14px;
    opacity: 1;

    width: 380px;
    height: 306px;
    background: #ffffff;
    border-radius: 14px 14px 14px 14px;
    opacity: 1;

    &:hover {
      opacity: 1;
      border: 1px solid #15c7ff;
      box-shadow: 0px 12px 40px 0px #d5e6f4;
      border-radius: 16px 16px 16px 16px;
    }

    .home-center-introduce-img {
      width: 100%;
      height: 130.5px;

      .home-center-introduce-img-url {
        width: 100%;
      }
    }

    .home-center-introduce-title-lab {
      margin-top: 26px;
      height: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      white-space: pre-line;
      text-indent: 2;

      .home-center-introduce-title-img {
        width: 19px;
        height: 19px;
      }
    }

    .home-center-introduce-detail-lab {
      height: 16px;
      font-size: 14px;
      margin-top: 20px;
      padding-left: 25px;

      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 26px;
      white-space: pre-line;
    }
  }

}

.home-center-staff-info-title {
  text-align: center;
  height: 40px;
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 38px;

  .home-center-staff-info-small-title {
    height: 18px;
    font-size: 18px;

    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 18px;
  }
}

.home-center-staff-info-img {
  margin-top: 60px;
  width: 1200px;
}

.home-center-core {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 180px;

}

.home-center-core-technology-title {
  text-align: center;
  height: 40px;
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 38px;

  .home-center-core-technology-small-title {
    height: 18px;
    font-size: 18px;

    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 18px;
  }
}

.home-center-core-technology-img {
  margin-top: 60px;
  width: 1080px;
}

.home-center-price-warpper {
  width: 1200px;
  height: 592px;
  position: relative;
  margin-top: 180px;

  .home-center-price-top-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 1200px;
    height: 160px;

    .home-center-price-top-bg-img {
      width: 100%;
      border-radius: 30px 30px 0px 0px;
    }

    .home-center-price-top-title {
      position: absolute;
      top: 36px;
      left: 36px;
      font-size: 32px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
    }

    .home-center-price-top-detail-title {
      font-size: 16px;

      font-weight: 400;
      color: rgba(255, 255, 255, 0.6);
      line-height: 18px;
    }

    .home-center-price-top-so-img {
      position: absolute;
      right: 32px;
      top: 21px;
      width: 336px;
      height: 72px;
    }
  }

  .home-center-price-collect-box {
    position: absolute;
    width: 1200px;
    height: 462px;
    background: linear-gradient(180deg, #e4f2fd 0%, #f6fbff 100%);
    box-shadow: 0px 10px 60px 0px rgba(0, 91, 183, 0.04);
    border-radius: 30px 30px 30px 30px;
    opacity: 1;
    border: 2px solid #ffffff;
    left: 0;
    top: 130px;
    display: flex;
    align-items: center;

    .home-center-price-collect-content {
      width: 1124px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

}

.home-center-pricecell-warpper {

  width: 360px;
  height: 388px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  position: relative;

  &:hover {
    box-shadow: 0px 12px 40px 0px #d5e6f4;
    border-radius: 16px 16px 16px 16px;
  }

  .home-center-price-cell-btn {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: 140px;
    height: 48px;
    background: #226cff;
    border-radius: 40px 40px 40px 40px;
    opacity: 1;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: #159DFF;
    }
  }

  .home-center-price-cell-title-one-box {
    margin-top: 26px;
    margin-left: 26px;
    width: 112px;
    height: 28px;
    font-size: 22px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
  }

  .home-center-price-cell-title-two-box {
    margin-top: 26px;
    margin-left: 26px;
    display: flex;
    align-items: center;
    height: 28px;
    font-size: 22px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 28px;
    background: linear-gradient(312deg, #406eb2 0%, #7fb7eb 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    .home-center-price-cell-sub-title {
      margin-left: 20px;
      width: 170px;
      height: 33px;
      background: linear-gradient(302deg,
          rgba(54, 86, 255, 0.13) 0%,
          rgba(182, 219, 255, 0.26) 52%,
          rgba(0, 171, 244, 0.06) 100%);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid;
      border-image: radial-gradient(circle,
          rgba(121, 130, 228, 0.13),
          rgba(121, 130, 228, 0)) 1 1;
      text-align: center;
      font-size: 12px;

      font-weight: 400;
      line-height: 33px;
      color: #226cff;
    }
  }

  .home-center-price-cell-title-three-box {
    margin-top: 26px;
    margin-left: 26px;
    display: flex;
    align-items: center;
    height: 28px;
    font-size: 22px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 28px;
    background: linear-gradient(312deg, #ce8440 0%, #f7bf8c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    .home-center-price-cell-sub-title {
      margin-left: 20px;
      width: 170px;
      height: 33px;
      background: linear-gradient(302deg,
          rgba(54, 86, 255, 0.13) 0%,
          rgba(182, 219, 255, 0.26) 52%,
          rgba(0, 171, 244, 0.06) 100%);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid;
      border-image: radial-gradient(circle,
          rgba(121, 130, 228, 0.13),
          rgba(121, 130, 228, 0)) 1 1;
      text-align: center;
      font-size: 12px;

      font-weight: 400;
      line-height: 33px;
      color: #226cff;
    }
  }

  .home-center-price-cell-content-box {
    margin-top: 34px;
    margin-left: 26px;

    .home-center-price-cell-content-list {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 400;
      color: #6e83a4;
      line-height: 32px;

      .home-center-price-cell-content-list-img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .home-center-price-cell-price-lab {
    position: absolute;
    bottom: 113px;
    left: 26px;
    color: #333333;
    font-family: PingFang SC-Semibold, PingFang SC;
    display: flex;
    align-items: baseline;
  }
}
</style>