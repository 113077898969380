<!--  -->
<template>
  <div>

    <div class="homecontactservicewarpper">
      <div class='home-contact-service-img' @click.stop="kfShowAct">
        <!-- -->
        <img v-show="!kfShow" class="service-img"
          src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/findCustomerService.png" alt="">
        <!-- <div class='home-contact-service-code-img-box'>
          <div class="tips1">扫码联系客服</div>
          <div class="tips2">提供数字员工技术及解决方案</div>
          <img src="https://wiseai-aicc.oss-cn-hangzhou.aliyuncs.com/APP/sys_img/digitalEmployee/card/QRCode2.png"
            alt="">
        </div> -->
        <!-- <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/customerServiceQR.png  "
          class='home-contact-service-code-img home-contact-service-code-img-show' /> -->

      </div>
    </div>
    <div class='home-contact-service-code-img-box' v-show="kfShow" @mousedown.stop="dragStart" ref="draggable">
      <div class="kf-top-box">
        <div class="left-box">
          <img src="@/assets//img/kefu/head-user.png" alt="">
          <div class="title-box">在线客服</div>
        </div>

        <div class="btn-box">
          <div class="item-btn amplify-btn-box" @click.stop="amplifyAct">
            <div class="amplify-btn"></div>
          </div>
          <div class="item-btn close-btn-box" @click.stop="kfShow = false">
            <i class="el-icon-close"></i>
          </div>
        </div>

      </div>
      <div class="iframeBox" v-if="!isFirstShowKf">
        <!-- 解决问题------在包含iframe的HTML中实现Vue拖动功能时，导致拖动操作在接近或越过iframe边界时中断 -->
        <!-- 在iframe上方添加透明遮罩层，在鼠标按下（mousedown）的时候出现，鼠标松开（mouseip）的时候消失，可解决该问题；一直展示也可以 -->
        <div class="iframeDiv" v-show="isDragging" ref="iframeDiv"></div>
        <iframe style="width: 100%;height: 100%;" :src="this.$currentChat" frameborder="0"></iframe>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      kfShow: false,
      // 第一次打开弹窗，触发iframe中的事件（滚动到底部
      isFirstShowKf: true, // 是否第一次展示客服弹窗
      startX: 0,
      startY: 0,
      startMouseX: 0,
      startMouseY: 0,
      isDragging: false, // 是否正在拖动
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    const iframes = document.querySelectorAll('iframe');
    iframes.forEach(iframe => {
      iframe.contentWindow.addEventListener('mousedown', (event) => {
        event.stopPropagation();
      });
    });
  },
  methods: {
    kfShowAct () {
      this.isFirstShowKf = false
      this.kfShow = true;
    },
    dragStart (e) {
      this.isDragging = true
      this.startX = this.$refs.draggable.offsetLeft;
      this.startY = this.$refs.draggable.offsetTop;
      this.startMouseX = e.clientX;
      this.startMouseY = e.clientY;

      document.addEventListener('mousemove', this.dragging);
      document.addEventListener('mouseup', this.dragEnd);

      // 阻止默认事件
      e.preventDefault();
    },
    dragging (e) {
      if (!this.isDragging) return
      const dx = e.clientX - this.startMouseX;
      const dy = e.clientY - this.startMouseY;

      // 计算新的位置
      let newX = this.startX + dx;
      let newY = this.startY + dy;

      // 获取窗口的宽度和高度
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      // 获取容器的宽度和高度
      const containerWidth = this.$refs.draggable.offsetWidth;
      const containerHeight = this.$refs.draggable.offsetHeight;

      // 限制容器移动范围，使其不超出窗口
      newX = Math.max(0, Math.min(newX, windowWidth - containerWidth));
      newY = Math.max(0, Math.min(newY, windowHeight - containerHeight));

      // 更新容器的位置
      this.$refs.draggable.style.left = `${newX}px`;
      this.$refs.draggable.style.top = `${newY}px`;

      // 阻止默认事件
      e.preventDefault();
    },
    dragEnd () {
      this.isDragging = false
      document.removeEventListener('mousemove', this.dragging);
      document.removeEventListener('mouseup', this.dragEnd);
    },

    amplifyAct () {
      window.open(this.$currentChat)

    }
  },
  beforeDestroy () {
    document.removeEventListener('mousemove', this.dragging);
    document.removeEventListener('mouseup', this.dragEnd);
  }
}
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */
.homecontactservicewarpper {
  z-index: 9;
  position: fixed;
  bottom: 200px;
  right: 20px;

  .home-contact-service-img {
    position: relative;
    width: 56px;
    height: 140px;
    // background-image: url("https://cdn.shhd.info/APP/sys_img/digitalEmployee/findCustomerService.png ");
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: 56px 140px;
    // cursor: pointer;

    .service-img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .home-contact-service-code-img {
      position: absolute;
      top: 0;
      right: 70px;
      width: 200px;
      height: 200px;

      img {
        width: 100px;
        height: 100px;
      }
    }

    .home-contact-service-code-img-show {
      display: none;
    }


  }
}

.home-contact-service-code-img-box {
  // position: absolute;
  // bottom: -180px;
  // right: 0px;
  z-index: 10;
  position: fixed;
  left: '';
  right: 20px;
  bottom: 20px;
  width: 450px;
  height: 70vh;
  border-radius: 14px;
  box-shadow: 0px 0 30px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;

  .tips1 {
    font-weight: 600;
    color: black;
    font-size: 17px;
  }

  .tips2 {
    color: #9d9d9d;
    font-size: 11px;
    margin-top: 8px;
  }

  .kf-top-box {
    width: 100%;
    height: 60px;
    background: #409EFF;
    cursor: move;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 0 0 12px;

    .left-box {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 40px;
        height: 40px;
      }

      .title-box {
        color: #ffffff;
        font-size: 14px;
        margin-left: 9px;
      }
    }

    .btn-box {
      display: flex;
      align-items: center;

      .item-btn {
        height: 60px;
        // width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .el-icon-close {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
      }

      .amplify-btn {
        width: 15px;
        height: 14px;
        border: 2px solid #ffffff;
        border-radius: 3px;
      }

      .amplify-btn-box {
        box-sizing: border-box;
        padding: 0 8px 0 15px;
      }

      .close-btn-box {
        box-sizing: border-box;
        padding: 0 15px 0 8px;
      }
    }

  }

  .iframeBox {
    width: 100%;
    height: calc(100% - 60px);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .05);
    position: relative;
    background-color: #ffffff;

    .iframeDiv {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1111;
      opacity: 0;
    }
  }
}
</style>