<!--  -->
<template>
  <div style="width: 100%;height: calc(100vh - 56px);overflow-y: auto;box-sizing: border-box;padding: 0 20px;">
    <Hometop @updataIndexAction="updateIndex" @openLoginAction="openLogin"></Hometop>
    <HomeCenter></HomeCenter>
    <HomeBottom ref="childBottom"></HomeBottom>
    <HomeBottomIntroduce></HomeBottomIntroduce>
    <BackToTop></BackToTop>
    <HomeContactService></HomeContactService>
  </div>
</template>

<script>
import Hometop from "./c-cpns/Hometop.vue";
import HomeCenter from "./c-cpns/HomeCenter.vue";
import HomeBottom from "./c-cpns/HomeBottom.vue";
import HomeBottomIntroduce from "./c-cpns/HomeBottomIntroduce.vue";
import BackToTop from "../../components/back-to-top/backtop.vue";
import HomeContactService from "./c-cpns/HomeContactService.vue";
export default {
  components: {
    Hometop,
    HomeCenter,
    HomeBottom,
    HomeBottomIntroduce,
    BackToTop,
    HomeContactService
  },

  data () {
    return {

    }
  },
  methods: {
    jumbQuestion () {
      this.$refs.childBottom.jumbQuestion2()
    },
    updateIndex () {
      this.$emit("updataIndexAction")
    },
    openLogin (index) {
      console.log("12312312");
      this.$emit("openloginAction", index)
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

    console.log("homehomehomehomehomehome");
  },

}
</script>
<style scoped>
/* @import url(); 引入css类 */
/* .home-header {
  z-index: 99;
  width: 100%;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 1px 1px 0px rgba(9, 56, 128, 0.06);
  opacity: 1;
  
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.logo-img {
  width: 102px;
  height: 38px;
  margin-left: 34px;
  cursor: pointer;
  /* position: absolute;
  left: 0; */
}

.home-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-nav {
  line-height: 56px;
  padding-left: 50px;
  padding-right: 50px;
  cursor: pointer;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.home-right {
  /* position: absolute;
  right: 0; */
  margin-right: 25px;

}

.home-right-person {
  display: flex;
  align-items: center;
}

.home-ps-p {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 14px;
  margin-right: 6px;
}

.home-ps-img {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  opacity: 1;
  border: 1px solid rgba(0, 0, 0, 0.03);
  cursor: pointer;
  background-color: #ff0000;
}

.login-btn {
  width: 108px;
  height: 36px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 36px;
  margin-right: 10px;
  /* background-color: red; */
  cursor: pointer;
  text-align: center;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #E6EBF3;
}

.free-use-btn {
  width: 108px;
  height: 36px;
  background: linear-gradient(270deg, #226cff 0%, #0688ff 100%);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;

  cursor: pointer;
}
</style>