<!--  -->
<template>
  <div>
    <div style="background: #e8f0f8;">
      <div class="home-bottom-introduce-content">
        <div class="home-bottom-introduce-content-left">
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/LOGO2.png"
            class="home-bottom-introduce-content-left-img" />
          <div class="home-bottom-introduce-content-left-info">
            <span>
              Copyright © 2023. 杭州慧言互动科技有限公司 All rights reserved.
            </span>
            <span style="padding-left: 30px;cursor: pointer;" @click="openRecordUrlAct">浙ICP备2023041918号</span>

          </div>
        </div>
        <div class="home-bottom-introduce-content-right">
          <div class="home-bottom-introduce-content-right-contact-us">
            联系我们 +86 17758003599
          </div>
          <div class="home-bottom-introduce-content-right-wx">客服微信</div>
          <img src="https://wiseai-aicc.oss-cn-hangzhou.aliyuncs.com/APP/sys_img/digitalEmployee/card/QRCode2.png"
            class="home-bottom-introduce-content-right-img" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  },
  methods: {
    // 打开备案号地址
    openRecordUrlAct () {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */
.home-bottom-introduce-content {
  width: 1200px;
  margin: 0 auto;
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .home-bottom-introduce-content-left {
    .home-bottom-introduce-content-left-img {
      width: 102px;
      height: 38px;
    }

    .home-bottom-introduce-content-left-info {
      margin-top: 20px;
      height: 12px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 12px;
    }
  }

  .home-bottom-introduce-content-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .home-bottom-introduce-content-right-contact-us {
      padding-right: 60px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 14px;
    }

    .home-bottom-introduce-content-right-wx {
      padding-right: 20px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 14px;
    }

    .home-bottom-introduce-content-right-img {
      width: 80px;
      height: 80px;
    }
  }
}
</style>