<!--  -->
<template>
<div>
  <div class="home-bottom-content" id="home-bottom-content-s">
    <div class='home-title-main-lab'>常问问题</div>
       
    <div class="home-bottom-question-warpper">

      <div
      v-for="(item,index) in dataArr" class="home-bottom-questioncell-warpper">
        <div class='home-bottom-question-cell-topline'></div>
        <div class='home-bottom-question-cell-question-lab'>
          <i class="el-icon-question"></i> {{item.question}}
        </div>
        <div class='home-bottom-question-cell-answer-lab'>{{item.answer}}</div>
    </div>

    </div>


    </div>
</div>
</template>

<script>
export default {
data() {
return {
  dataArr : [
    {
      question: "慧言AI是什么？有何具体功能？",
      answer:
        "慧言AI，或者更准确地说，AI数字员工，是我们开发的一款具有深度学习能力的人工智能产品。其主要目标是帮助企业解决传统人工方式无法覆盖的各问题，例如：回复不及时，人力不足，能力有限以及效率和风险问题等。通过不断从企业的数据中学习和提升，慧言AI能够实现全天候在线，快速准确回复问题，利用最新的AI模型将回答的真人模拟程度提高至极致，既满足了天时地利人和的需求，又大大降低了成本，可谓集万千优点于一身。",
    },
    {
        question: "慧言AI如何解决传统人工方式的问题？",
        answer:
          "慧言AI的出现巧妙地解决了许多传统人工方式存在的问题。例如，对于人工回复不及时的问题，慧言AI可以实现7*24小时的全天候在线服务，无论何时何地，只要有顾客咨询，都能够得到及时、准确的回复，有效避免客户因长时间等待而流失。此外，对于人工资源有限的情况，慧言AI通过一键上传企业的知识库，快速自我学习和训练，能有效填补人力资源不足的空白。而对于人工处理质量参差不齐，错误率高的问题，慧言AI的答案始终基于企业最准确的信息，一以贯之确保各种问题的标准答案。最重要的是，慧言AI能大大降低人工的成本，通过软件实现的自助服务，其成本仅为人工的1/20。",
      },
      {
        question: "慧言AI的训练过程是如何进行的？",
        answer:
          "使用慧言AI的过程非常简单易用。您只需要将您企业的知识、经验、品牌故事、公司理念等信息以文档、聊天记录或者prompt的形式进行上传。一旦文件上传完成，慧言AI就会自动进行学习和训练，通常在几秒钟之内便能完成。这样，在极短的时间内，您就能拥有一个已经接受过初步训练、能够满足基本需求的AI数字员工。",
      },
      {
        question: " 对于企业而言，慧言AI具体都有哪些应用场景？",
        answer:
          " 由于其灵活的学习和应用能力，慧言AI适合在很多场景中使用。例如，对于大量需要信息咨询、沟通的行业，尤其是招聘、电商等，慧言AI可以用于批量筛选意向客户，节省人力成本。对于需要专业知识教育客户的行业，如美容、健康、教培等，慧言AI可以提供精准的专业信息，提升客户满意度。此外，对于需要提供定制化服务的行业或者需要常态化维护的私域群，如药膳餐厅、牙科诊所等，慧言AI能通过个性化推荐提升转化率。当然，对内部团队/代理商赋能支持，以及公司内部岗位替代/辅助等场景也是慧言AI展现强大功能的地方。",
      },
      {
        question: "慧言AI的高效性体现在哪些方面？",
        answer:
          "慧言AI的高效性主要体现在三个方面。首先，通过24/7全天候在线响应，慧言AI可以提供实时、即时的客户服务，大大提高了企业处理客户问题的效率，不会影响到客户的服务体验。其次，通过一键上传企业的知识库，慧言AI可以在几秒钟内快速完成知识的学习和训练，省去了培训人工员工的时间和成本。最后，慧言AI的运用成本仅为传统的人工服务的1/20，降低了企业的运营成本。",
      },
      {
        question: "为什么选择慧言AI，而不是传统的人工客服？",
        answer:
          "有几个原因使慧言AI成为一个比传统人工客服更好的选择。首先，慧言AI可以24/7全天候在线，提供实时、精准的回答，而不会因为人力不足而导致服务的滞后。其次，传统的人工客服可能会面临文案、策划等专业工作完成质量参差不齐，错误率高等问题，但是慧言AI在这些方面表现优异。另外，慧言AI支持多语言自适应，对于国际化的企业，可以更好地服务不同语言的客户群体。最后，与人工员工相比，使用慧言AI减少了企业的招聘、培训及离职等综合成本。",
      },
  ],
}
  },
  methods: {

    jumbQuestion2 () { 
      console.log("456");
      var cc = document.querySelector("#home-bottom-content-s");
        let bridge = cc;
        let body = document.body;
        let height = 0; // 计算该 DOM 节点到 body 顶部距离
        do {
          height += bridge.offsetTop;
          bridge = bridge.offsetParent;
        } while (bridge !== body); // 滚动到指定位置
        // console.log(height);
        window.scrollTo({
          top: height,
          behavior: "smooth",
        });
    }
  },
//生命周期 - 创建完成（访问当前this实例）
created() {

},
//生命周期 - 挂载完成（访问DOM元素）
mounted() {

}
}
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */
.home-bottom-content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 180px;
    padding-bottom: 120px;
  }

  .home-title-main-lab {
    width: 160px;
    height: 30px;
    font-size: 30px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 40px;
  }

  .home-bottom-question-warpper{
    display:flex ;
justify-content: space-between;
flex-wrap: wrap;
  }
  .home-bottom-questioncell-warpper{
    margin-top: 80px;
width: 560px;
height: 224px;
.home-bottom-question-cell-topline{
    width: 100%;
    height: 1px;
    background: #DDE8F2;
}
.home-bottom-question-cell-question-lab{
    margin-top:60px ;
height: 20px;
font-size: 20px;
font-family: PingFang SC-Semibold, PingFang SC;
font-weight: 600;
color: #000000;
line-height: 20px;
}
.home-bottom-question-cell-answer-lab{
    margin-top:20px ;
    font-family: PingFang SC-Semibold, PingFang SC; 
font-size: 15px;
 
font-weight: 400;
color: rgba(0,0,0,0.5);
line-height: 26px;

}
  }
</style>